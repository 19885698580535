<template>
  <div class="main">
    <Header></Header>
    <div class="indexHeader">
      <div class="text1">挖掘看不见世界中的价值</div>
      <div class="text2">
        新基建背景下重新定义工业互联网
      </div>
      <div class="text2">
        赋能工业企业数字化升级
      </div>
    </div>
    <div class="indexcarousel">
      <div style="position: absolute; top: 20px; left: 20px; z-index: 10;">
        <div class="line"></div>
        <div class="linetitle">行业解决方案</div>
      </div>
      <el-carousel ref="carousel">
        <el-carousel-item
          v-for="(item, index) in items"
          :key="index"
          :label="item.title"
          style="font-size: 24px;"
        >
          <img :src="item.image" alt="" @click="getUrl(item.url)" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="coreCompetencies">
      <div style="margin-top: 20px">
        <div class="line"></div>
        <div class="linetitles">核心能力</div>
      </div>
      <div class="competentpic">
        <i
          class="el-icon-arrow-left"
          style="font-size: 32px; color: #bfbfbf;"
          @click="scrollLeft"
        ></i>
        <div class="scroll-container">
          <div
            v-for="(item, index) in piclist"
            :key="index"
            @mouseenter="onMouseEnter(index)"
            @mouseleave="onMouseLeave"
          >
            <img
              v-if="picindex == index"
              :src="item.aimg"
              alt=""
              style="width: 475px; height: 586px;"
            />
            <img
              v-else
              :src="item.bimg"
              alt=""
              style="width: 475px; height: 586px;"
            />
          </div>
        </div>
        <i
          class="el-icon-arrow-right"
          style="font-size: 32px; color: #bfbfbf;"
          @click="scrollRight"
        ></i>
      </div>
    </div>
    <div class="coreCompetencies">
      <div style="margin: 20px 0 40px 0">
        <div class="line"></div>
        <div class="linetitles">荣誉</div>
      </div>
      <div class="scrolling-honors">
        <div class="honors-list">
          <div class="honor-item">舟山市智能服务公司</div>
          <div class="honor-item">舟山市5313领军人才项目</div>
          <div class="honor-item">2020年浙江省数字经济“五新”优秀案例</div>
          <div class="honor-item">浙江省第一批中小企业专业化服务机构</div>
          <div class="honor-item">浙江省省级工业互联网创建平台</div>
          <div class="honor-item">浙江省科技型中小企业</div>
        </div>
        <div class="honors-list">
          <div class="honor-item">舟山市智能服务公司</div>
          <div class="honor-item">舟山市5313领军人才项目</div>
          <div class="honor-item">2020年浙江省数字经济“五新”优秀案例</div>
          <div class="honor-item">浙江省第一批中小企业专业化服务机构</div>
          <div class="honor-item">浙江省省级工业互联网创建平台</div>
          <div class="honor-item">浙江省科技型中小企业</div>
        </div>
      </div>
      <div class="scrolling-honors">
        <div class="honors-list">
          <div class="honor-item">浙江省第一批中小企业专业化服务机构</div>
          <div class="honor-item">浙江省省级工业互联网创建平台</div>
          <div class="honor-item">浙江省科技型中小企业</div>
          <div class="honor-item">浙江省省级产业数字化服务商</div>
          <div class="honor-item">智能制造工程服务机构</div>
          <div class="honor-item">舟山炼化一体化新材料产业链工业互联网平台</div>
        </div>
        <div class="honors-list">
          <div class="honor-item">浙江省第一批中小企业专业化服务机构</div>
          <div class="honor-item">浙江省省级工业互联网创建平台</div>
          <div class="honor-item">浙江省科技型中小企业</div>
          <div class="honor-item">浙江省省级产业数字化服务商</div>
          <div class="honor-item">智能制造工程服务机构</div>
          <div class="honor-item">舟山炼化一体化新材料产业链工业互联网平台</div>
        </div>
      </div>
      <div class="scrolling-honors">
        <div class="honors-list">
          <div class="honor-item">浙江省省级产业数字化服务商</div>
          <div class="honor-item">智能制造工程服务机构</div>
          <div class="honor-item">舟山炼化一体化新材料产业链工业互联网平台</div>
          <div class="honor-item">舟山市智能服务公司</div>
          <div class="honor-item">舟山市5313领军人才项目</div>
          <div class="honor-item">2020年浙江省数字经济“五新”优秀案例</div>
        </div>
        <div class="honors-list">
          <div class="honor-item">浙江省省级产业数字化服务商</div>
          <div class="honor-item">智能制造工程服务机构</div>
          <div class="honor-item">舟山炼化一体化新材料产业链工业互联网平台</div>
          <div class="honor-item">舟山市智能服务公司</div>
          <div class="honor-item">舟山市5313领军人才项目</div>
          <div class="honor-item">2020年浙江省数字经济“五新”优秀案例</div>
        </div>
      </div>
    </div>
    <div class="coreCompetencies">
      <div style="margin: 20px 0 40px 0">
        <div class="line"></div>
        <div class="linetitles">公司动态</div>
      </div>
      <div class="dynamic">
        <div
          class="centered-container"
          @click="netOpen('https://mp.weixin.qq.com/s/tHGMGljsu5oQ3efkUiJSKQ')"
        >
          <img
            src="~assets/img/newindex/gzh1.jpg"
            alt=""
            style="width: 100%; height: 380px;"
          />
          <div class="texttitle" style=" width: 100%;">
            三象数据入选2024中国未来独角鲸企业榜单
          </div>
          <div class="textdetail">
            9月25日，在宁波象山举办的第十八届中国海洋论上，中国太平洋学会与微链携手，共同揭晓了备受瞩目的2024中国未来“独角鲸”和未来“独角鲸捕手”榜单，100家分别来自功能材料、清洁能源等前沿领域，深耕海洋经济的企业荣登榜单。浙江三象数据有限公司作为空海信息代表性企业荣登榜单。
          </div>
        </div>
        <div class="centered-right">
          <div
            class="picitem"
            @click="
              netOpen('https://mp.weixin.qq.com/s/242rtDjEMZez_9jdTTYWog')
            "
          >
            <img
              src="~assets/img/newindex/gzh2.png"
              alt=""
              style="width: 25%; height: 140px"
            />
            <div style="width: 75%; padding: 0 10px;">
              <div class="texttitle" style=" width: 100%;">
                修造船数字化管控系统获得“强链品牌”认定证书
              </div>
              <div class="textdetails">
                近日，为提升船舶工业产业链供应链韧性，促进船舶工业企业产品品牌建设和产业链均衡发展，中国船舶工业行业协会首次启动了船舶工业“强链品牌”产品申报和认定工作。三象数据自主研发的修造船数字化管控系统荣登第一批船舶工业“强链品牌”产品目录。
              </div>
            </div>
          </div>
          <el-divider></el-divider>
          <div
            class="picitem"
            @click="
              netOpen('https://mp.weixin.qq.com/s/Ah8xIMI5b2PcCqZ0lRczhA')
            "
          >
            <img
              src="~assets/img/newindex/gzh3.jpg"
              alt=""
              style="width: 25%; height: 140px"
            />
            <div style="width: 75%; padding: 0 10px;">
              <div class="texttitle" style=" width: 100%;">
                象来科技（舟山群岛新区）有限公司参加2024南通船舶海工产业展会
              </div>
              <div class="textdetails">
                9月12日，2024高技术船舶和海工装备国家先进制造业集群（南通）发展大会暨船舶海工产业展在南通国际会议中心开幕，合力推动通泰扬船舶海工国家先进制造业集群向世界级集群跃升。
              </div>
            </div>
          </div>
          <el-divider></el-divider>
          <div
            class="picitem"
            @click="
              netOpen('https://mp.weixin.qq.com/s/ciwVJ9c1_7lk34pw7N7VzQ')
            "
          >
            <img
              src="~assets/img/newindex/gzh4.png"
              alt=""
              style="width: 25%; height: 140px"
            />
            <div style="width: 75%; padding: 0 10px;">
              <div class="texttitle" style=" width: 100%;">
                三象纺织行业云工厂平台上榜工信部2023年新一代信息技术典型产品、应用和服务案例（第一批）公示名单
              </div>
              <div class="textdetails">
                12月27号，工业和信息化部2023年新一代信息技术典型产品、应用和服务案例（第一批）名单公示，三象纺织行业云工厂平台创新应用案例成功上榜，案例应用企业为浙江澳亚织造股份有限公司。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="coreCompetencies">
      <div style="margin: 20px 0 40px 0">
        <div class="line"></div>
        <div class="linetitles">客户</div>
      </div>
      <div style="width: 92%; margin: 0 4%; ">
        <div class="customer-honors">
          <div class="customer-list">
            <div class="customeritem">
              <img
                src="~assets/img/newindex/logo1.png"
                alt=""
                height="120px"
                width="120px"
              />
            </div>
            <div class="customeritem"></div>
            <div class="customeritem">
              <img
                src="~assets/img/newindex/logo2.png"
                alt=""
                height="120px"
                width="120px"
              />
            </div>
            <div class="customeritem"></div>
            <div class="customeritem">
              <img
                src="~assets/img/newindex/logo3.png"
                alt=""
                height="120px"
                width="120px"
              />
            </div>
            <div class="customeritem"></div>
            <div class="customeritem">
              <img
                src="~assets/img/newindex/logo4.png"
                alt=""
                height="120px"
                width="120px"
              />
            </div>
            <div class="customeritem"></div>
            <div class="customeritem">
              <img
                src="~assets/img/newindex/logo5.png"
                alt=""
                height="120px"
                width="120px"
              />
            </div>
            <div class="customeritem"></div>
            <div class="customeritem">
              <img
                src="~assets/img/newindex/logo6.png"
                alt=""
                height="120px"
                width="120px"
              />
            </div>
            <div class="customeritem"></div>
          </div>
          <div class="customer-list">
            <div class="customeritem">
              <img
                src="~assets/img/newindex/logo1.png"
                alt=""
                height="120px"
                width="120px"
              />
            </div>
            <div class="customeritem"></div>
            <div class="customeritem">
              <img
                src="~assets/img/newindex/logo2.png"
                alt=""
                height="120px"
                width="120px"
              />
            </div>
            <div class="customeritem"></div>
            <div class="customeritem">
              <img
                src="~assets/img/newindex/logo3.png"
                alt=""
                height="120px"
                width="120px"
              />
            </div>
            <div class="customeritem"></div>
            <div class="customeritem">
              <img
                src="~assets/img/newindex/logo4.png"
                alt=""
                height="120px"
                width="120px"
              />
            </div>
            <div class="customeritem"></div>
            <div class="customeritem">
              <img
                src="~assets/img/newindex/logo5.png"
                alt=""
                height="120px"
                width="120px"
              />
            </div>
            <div class="customeritem"></div>
            <div class="customeritem">
              <img
                src="~assets/img/newindex/logo6.png"
                alt=""
                height="120px"
                width="120px"
              />
            </div>
            <div class="customeritem"></div>
          </div>
        </div>
        <div class="customer-honors">
          <div class="customer-list">
            <div class="customeritem"></div>
            <div class="customeritem">
              <img
                src="~assets/img/newindex/logo7.png"
                alt=""
                height="120px"
                width="120px"
              />
            </div>
            <div class="customeritem"></div>
            <div class="customeritem">
              <img
                src="~assets/img/newindex/logo8.png"
                alt=""
                height="120px"
                width="120px"
              />
            </div>
            <!-- <div class="customeritem"></div>
            <div class="customeritem">
              <img
                src="~assets/img/newindex/logo9.png"
                alt=""
                height="120px"
                width="240px"
              />
            </div> -->
            <div class="customeritem"></div>
            <div class="customeritem">
              <img
                src="~assets/img/newindex/logo10.png"
                alt=""
                height="120px"
                width="120px"
              />
            </div>
            <div class="customeritem"></div>
            <div class="customeritem">
              <img
                src="~assets/img/newindex/logo11.png"
                alt=""
                height="120px"
                width="120px"
              />
            </div>
            <div class="customeritem"></div>
            <div class="customeritem">
              <img
                src="~assets/img/newindex/logo12.png"
                alt=""
                height="120px"
                width="120px"
              />
            </div>
            <div class="customeritem"></div>
            <div class="customeritem">
              <img
                src="~assets/img/newindex/logo13.png"
                alt=""
                height="120px"
                width="120px"
              />
            </div>
          </div>
          <div class="customer-list">
            <div class="customeritem"></div>
            <div class="customeritem">
              <img
                src="~assets/img/newindex/logo7.png"
                alt=""
                height="120px"
                width="120px"
              />
            </div>
            <div class="customeritem"></div>
            <div class="customeritem">
              <img
                src="~assets/img/newindex/logo8.png"
                alt=""
                height="120px"
                width="120px"
              />
            </div>
            <!-- <div class="customeritem"></div>
            <div class="customeritem">
              <img
                src="~assets/img/newindex/logo9.png"
                alt=""
                height="120px"
                width="240px"
              />
            </div> -->
            <div class="customeritem"></div>
            <div class="customeritem">
              <img
                src="~assets/img/newindex/logo10.png"
                alt=""
                height="120px"
                width="120px"
              />
            </div>
            <div class="customeritem"></div>
            <div class="customeritem">
              <img
                src="~assets/img/newindex/logo11.png"
                alt=""
                height="120px"
                width="120px"
              />
            </div>
            <div class="customeritem"></div>
            <div class="customeritem">
              <img
                src="~assets/img/newindex/logo12.png"
                alt=""
                height="120px"
                width="120px"
              />
            </div>
            <div class="customeritem"></div>
            <div class="customeritem">
              <img
                src="~assets/img/newindex/logo13.png"
                alt=""
                height="120px"
                width="120px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <el-backtop
      ><img
        src="~assets/img/index/icon-回到顶部@2x.png"
        alt="icon-回到顶部@2x.png"
    /></el-backtop>
  </div>
</template>

<script>
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
export default {
  name: "index",
  data() {
    return {
      drawer: false,
      picindex: null,
      items: [
        {
          image: require("@/assets/img/Header/caro1.png"),
          title: "纺织行业",
          url: "SolutionTwo"
        },
        {
          image: require("@/assets/img/Header/caro2.png"),
          title: "紧固件行业",
          url: "SolutionOne"
        },
        {
          image: require("@/assets/img/Header/caro3.png"),
          title: "船舶海工行业",
          url: ""
        },
        {
          image: require("@/assets/img/Header/caro4.png"),
          title: "汽车零部件行业",
          url: "SolutionThree"
        }
      ],
      piclist: [
        {
          aimg: require("@/assets/img/newindex/pic1.png"),
          bimg: require("@/assets/img/newindex/pics1.png")
        },
        {
          aimg: require("@/assets/img/newindex/pic2.png"),
          bimg: require("@/assets/img/newindex/pics2.png")
        },
        {
          aimg: require("@/assets/img/newindex/pic3.png"),
          bimg: require("@/assets/img/newindex/pics3.png")
        },
        {
          aimg: require("@/assets/img/newindex/pic4.png"),
          bimg: require("@/assets/img/newindex/pics4.png")
        },
        {
          aimg: require("@/assets/img/newindex/pic5.png"),
          bimg: require("@/assets/img/newindex/pics5.png")
        },
        {
          aimg: require("@/assets/img/newindex/pic6.png"),
          bimg: require("@/assets/img/newindex/pics6.png")
        }
      ],
      scrollAmount: 2000 // 每次滚动的距离
    };
  },
  methods: {
    onMouseEnter(index) {
      this.picindex = index;
    },
    onMouseLeave() {
      this.picindex = null;
    },
    netOpen(value) {
      window.open(value);
    },
    getUrl(url) {
      if (url) {
        this.$router.push({
          path: url,
          name: url,
          params: ""
        });
      }
    },
    scrollLeft() {
      const container = this.$el.querySelector(".scroll-container");
      container.scrollLeft -= this.scrollAmount;
    },
    scrollRight() {
      const container = this.$el.querySelector(".scroll-container");
      container.scrollLeft += this.scrollAmount;
    }
  },
  mounted() {},
  components: {
    Header,
    Footer
  }
};
</script>
<style lang="less" scoped>
.main {
  width: 100%;
  background-color: #f8f9fb;
  position: relative;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 98%;
  margin-left: 1%;
  display: flex;
  justify-content: space-between;
}
.leftContent {
  width: 315px;
  height: 40px;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  img {
    width: 114px;
    height: 23px;
    margin-top: 10px;
    vertical-align: text-bottom;
  }
  .dtsumName {
    vertical-align: middle;
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    letter-spacing: 3px;
    color: rgba(51, 51, 51, 0.65);
  }
}
.rightContent {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 40px;
    height: 40px;
    vertical-align: text-bottom;
  }
}
.indexHeader {
  width: 100%;
  height: 1050px;
  background: url("~assets/img/newindex/img1.png") no-repeat;
  background-size: 100% 100%;
  margin: 0 auto;
  .text1 {
    padding-top: 371px;
    padding-left: 140px;
    font-family: OPlusSans 3, OPlusSans 30;
    font-weight: bold;
    font-size: 54px;
    color: #1878ff;
    line-height: 58px;
    letter-spacing: 1px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
  .text2 {
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 36px;
    color: rgba(51, 51, 51, 0.45);
    padding-top: 20px;
    padding-left: 140px;
  }
}
.drawermain {
  width: 100%;
  height: 100%;
  background: url("~assets/img/newindex/drawer.png") no-repeat;
  background-size: 100% 100%;
  margin: 0 auto;
}
.close {
  display: flex;
  justify-content: end;
  img {
    width: 40px;
    height: 40px;
    vertical-align: text-bottom;
  }
}
.drawertop {
  width: 92%;
  margin-left: 4%;
  display: flex;
  justify-content: space-around;
}
.drawerline {
  width: 23%;
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.drawerline2 {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.title1 {
  font-family: OPlusSans 3, OPlusSans 30;
  font-weight: bold;
  font-size: 17px;
  color: #1878ff;
  text-align: left;
  font-style: normal;
  text-transform: none;
  padding: 10px;
}
.title2 {
  padding: 10px;
  font-family: OPlusSans 3, OPlusSans 30;
  font-weight: 500;
  font-size: 17px;
  color: rgba(51, 51, 51, 0.85);
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.middletitle {
  font-family: OPlusSans 3, OPlusSans 30;
  font-weight: bold;
  font-size: 18px;
  color: #006aff;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-left: 5%;
}
.indexcarousel {
  width: 100%;
  margin-top: 10px;
  position: relative;
  img {
    width: 100%;
    height: 960px;
    vertical-align: text-bottom;
  }
}
.line {
  width: 180px;
  height: 4px;
  background: #3d95ff;
  border-radius: 0px 0px 0px 0px;
}
.linetitle {
  font-family: OPlusSans 3, OPlusSans 30;
  font-weight: bold;
  font-size: 20px;
  color: #ffffff;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-top: 5px;
}
.linetitles {
  font-family: OPlusSans 3, OPlusSans 30;
  font-weight: bold;
  font-size: 20px;
  color: #000000;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-top: 5px;
}
.coreCompetencies {
  margin: 50px 0;
  padding: 0 40px;
}
.competentpic {
  width: 100%;
  margin: 30px 0;
  display: flex;
  justify-content: start;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.scroll-container {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
  scroll-behavior: smooth;
  padding-bottom: 10px; /* 可以根据需要调整 */
}
/* 隐藏滚动条 */
.scroll-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
// .scroll-container::-webkit-scrollbar {
//   height: 10px; /* 滚动条的高度 */
// }

// .scroll-container::-webkit-scrollbar-thumb {
//   background-color: rgba(0, 0, 0, 0.3); /* 滚动条的背景颜色 */
//   border-radius: 5px; /* 滚动条的圆角 */
// }

.scroll-container div {
  margin-right: 15px; /* 图片之间的间隔 */
}

.scroll-container img {
  width: 100%;
  height: 400px;
  object-fit: cover; /* 确保图片填充整个容器 */
}
.scrolling-honors {
  position: relative;
  width: 100%;
  overflow: hidden;
  white-space: nowrap; /* 使内容在一行内显示 */
  user-select: none; /* 禁止文本选择 */
  margin-bottom: 100px;
}

.honors-list {
  display: inline-flex;
  animation: scrollText 20s linear infinite; /* 控制滚动速度和方向 */
  will-change: transform; /* 提示浏览器提前准备好硬件加速 */
}

@keyframes scrollText {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.honor-item {
  padding: 65px 45px;
  margin-right: 10px;
  white-space: nowrap;
  background: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0.8) 63%,
    rgba(255, 250, 226, 0.6) 100%
  );
  box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.05),
    0px 2px 10px 0px rgba(0, 0, 0, 0.03);
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #ffffff;
  font-family: OPlusSans 3, OPlusSans 30;
  font-weight: 500;
  font-size: 40px;
  color: #e5b040;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.customer-honors {
  position: relative;
  width: 100%;
  height: 120px;
  overflow: hidden;
  white-space: nowrap; /* 使内容在一行内显示 */
  user-select: none; /* 禁止文本选择 */
  margin-top: 15px;
}
.customer-list {
  width: 100%;
  display: inline-flex;
  animation: customerText 20s linear infinite; /* 控制滚动速度和方向 */
  will-change: transform; /* 提示浏览器提前准备好硬件加速 */
}
.customeritem {
  width: 20%;
  display: flex;
  justify-content: center;
}
@keyframes customerText {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.dynamic {
  width: 90%;
  margin: 0 5%;
  display: flex;
  justify-content: space-between;
}
.centered-container {
  width: 50%;
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  text-align: left;
  min-height: 100%;
  height: fit-content;
  margin-right: 10px;
}
.centered-right {
  width: 50%;
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  min-height: 100%;
  height: fit-content;
}
.texttitle {
  font-family: OPlusSans 3, OPlusSans 30;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: justified;
  font-style: normal;
  text-transform: none;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  display: -webkit-box; /* 兼容 WebKit 引擎 */
  -webkit-line-clamp: 1; /* 限制为两行 */
  -webkit-box-orient: vertical; /* 垂直排列 */
  overflow: hidden; /* 隐藏超出的内容 */
  line-clamp: 2; /* 标准属性 */
  text-overflow: ellipsis; /* 用省略号表示多余的内容 */
  margin-top: 5px;
}
.textdetail {
  font-family: OPlusSans 3, OPlusSans 30;
  font-weight: 500;
  font-size: 16px;
  color: rgba(51, 51, 51, 0.5);
  text-align: justified;
  font-style: normal;
  text-transform: none;
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 36px;
  display: -webkit-box; /* 兼容 WebKit 引擎 */
  -webkit-line-clamp: 2; /* 限制为两行 */
  -webkit-box-orient: vertical; /* 垂直排列 */
  overflow: hidden; /* 隐藏超出的内容 */
  line-clamp: 2; /* 标准属性 */
  text-overflow: ellipsis; /* 用省略号表示多余的内容 */
  margin-top: 5px;
}
.textdetails {
  font-family: OPlusSans 3, OPlusSans 30;
  font-weight: 500;
  font-size: 16px;
  color: rgba(51, 51, 51, 0.5);
  text-align: justified;
  font-style: normal;
  text-transform: none;
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 36px;
  display: -webkit-box; /* 兼容 WebKit 引擎 */
  -webkit-line-clamp: 3; /* 限制为两行 */
  -webkit-box-orient: vertical; /* 垂直排列 */
  overflow: hidden; /* 隐藏超出的内容 */
  line-clamp: 2; /* 标准属性 */
  text-overflow: ellipsis; /* 用省略号表示多余的内容 */
  margin-top: 5px;
}
.picitem {
  width: 100%;
  display: flex;
  justify-content: start;
}
/deep/ .el-carousel__container {
  height: 960px !important;
}
/deep/.el-carousel__indicators--labels .el-carousel__button {
  background: linear-gradient(
    360deg,
    rgba(241, 246, 255, 0.2) 0%,
    rgba(221, 236, 255, 0.1) 100%
  );
  border-radius: 4px 4px 4px 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-family: OPlusSans 3, OPlusSans 30;
  font-weight: bold;
  font-size: 40px;
  color: rgba(255, 255, 255, 0.5);
  line-height: 44px;
  text-align: center;
  font-style: normal;
  text-transform: none;
  margin-bottom: 70px;
}
/deep/.el-carousel__indicator.is-active button {
  opacity: 1;
  background: linear-gradient(
    360deg,
    rgba(241, 246, 255, 0.2) 0%,
    rgba(221, 236, 255, 0.1) 100%
  );
  border-radius: 4px 4px 4px 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-family: OPlusSans 3, OPlusSans 30;
  font-weight: bold;
  font-size: 40px;
  color: #ffffff;
  line-height: 44px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}
/deep/ .el-divider--horizontal {
  margin: 15px 0 !important;
}
</style>
